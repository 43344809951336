@import "reset";
@import "config";

body {
    color: $color-black;
    background: $color-white;
    font-family: 'Inconsolata', ;
    font-size: 100%;
    font-weight: 400;
}
h1 {
    margin-bottom: 5px;
}
p {
    font-size: 1em;
    line-height: 150%;
}
a {
  color: $color-link;
  text-decoration: none;
  &:visited {
    color: $color-link;
    text-decoration: none;
  }
  &:hover {
    color: $color-link;
    text-decoration: underline;
  }
  &:active {
    color: $color-link;
    text-decoration: none;
  }
}
ul {
    list-style: none;
    li {
        padding: 5px;
        padding-left: 10px;
        @media screen and (max-width: $br) {
            padding: 10px 0 10px 0;
        }
    }
}


.wrapper {
    max-width: 600px;
    margin: auto;
    padding: 60px 30px 60px 30px;
    @media screen and (max-width: $br) {
        padding: 20px;
    }
}


.header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-bottom: 50px;
    @media screen and (max-width: $br) {
        display: block;
    }
    h1 {
        font-size: 2em;
        margin-bottom: 0;
    }
    ul {
        margin-top: 10px;
        li {
            display: inline;
            padding: 0;
            padding-left: 5px;
        }
    }
}


.portfolio {
    padding-bottom: 30px;
    h1 {
        font-size: 1.4em;
        margin-bottom: 5px;
    }
}



.blog {
    padding-bottom: 30px;
    h1 {
        font-size: 1.4em;
        margin-bottom: 5px;
    }
}


.post {
    padding-bottom: 30px;
    &__title {
        font-size: 1.6em;
        font-weight: 700;
        text-decoration: underline;
        margin-top: 20px;
        margin-bottom: 5px;
    }
    &__date {
        font-size: 1em;
        font-style: italic;
    }
    &__edited {
	font-size: 1em;
	font-style: italic;
    }
    &__content {
        padding-top: 40px;
        h1 {
            font-size: 1.25em;
            font-weight: 700;
            margin-top: 30px;
            margin-bottom: 5px;
        }
        h2 {
            font-size: 1.00em;
            font-weight: 700;
            margin-top: 30px;
            margin-bottom: 5px;
        }
        p {
            font-size: 1em;
            line-height: 150%;
            margin-bottom: 15px;
        }
        a {
            text-decoration: underline;
            &:hover {
                font-weight: bold;
            }
        }
        strong {
            font-weight: 700;
        }
        em {
            font-style: italic;
        }
        ul {
            font-size: 1em;
            list-style: circle;
            margin-left: 30px;
            margin-top: -5px;
            margin-bottom: 5px;
            li {
                padding: 5px;
                padding-left: 5px;
                @media screen and (max-width: $br) {
                    padding: 10px 0 10px 0;
                }
            }
            @media screen and (max-width: $br) {
                margin-left: 10px;
            }
        }
        img {
            margin-top: 10px;
            margin-bottom: 10px;
            @media screen and (max-width: $br) {
                width: 100%;
                margin: auto;
            }
        }
        code {
            padding: 3px;
            font-size: 0.9em;
            border-radius: 3px;
            background: $color-grey;
        }
        blockquote {
          background: #e0e0e0;
          border-left: 10px solid #ccc;
          margin: 10px 0 10px 0;
          padding: 0.5em 10px;
          quotes: "\201C""\201D""\2018""\2019";
        }
        blockquote p {
          display: inline;
        }
        .highlight {
            margin: 10px;
            margin-bottom: 15px;
            padding: 10px;
            line-height: 1.5;
            border-radius: 3px;
            background: $color-black;
            code {
                color: $color-code;
                background: none;
            }
        }
        .codebreak {
            margin: 15px;
            border: 0;
            border-bottom: 1px dashed $color-black;
        }
    }
}

.about {
    &__title {
        font-size: 1.6em;
        font-weight: 700;
        margin-top: 20px;
        margin-bottom: 10px;
    }
    &__content {
        margin-top: 20px;
        h1 {
            font-size: 1.2em;
            font-style: italic;
            margin-top: 30px;
            margin-bottom: 5px;
        }
        p {
            font-size: 1em;
            line-height: 150%;
            margin-bottom: 10px;
        }
    }
}

.header-image {
  width: 200px;
  border-radius: 50%;
  // display: block;
  // margin: 0 auto;
}

.image {
  img {
    width: 100%;
  }
}

// Adding 'Contents' headline to the TOC
#markdown-toc::before {
    content: "Contents";
    font-weight: bold;
}


// Using numbers instead of bullets for listing
#markdown-toc ul {
    list-style: none;
    margin: 10px;
    padding: 0px;
}

#markdown-toc {
    border: 1px solid #aaa;
    padding: 2.5em;
    list-style: none;
    display: inline-block;
}

// Customize jekyll-scholar.
.bibliography {
    li {
	padding: 5px;
        list-style: none;
    }
}

// COLORS
$color-black: #272727;
$color-white: #FFFFFF;
$color-grey: #EEEEEE;
$color-link: #1A0DAB;
$color-code: #00FF00;
$color-hire-white: #FEFEFE;
$color-hire-black: #272727;
$color-hire-green: #B6EFD4;
$color-hire-blue: #A0CCDA;
$color-hire-shadow: rgba(0, 0, 0, 0.2);

// FONT
$font-url: "https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700,400italic";
$font-family: "'Ubuntu Mono', ";

// BREAKPOINTS
$br: 600px;
